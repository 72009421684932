import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { search, getLhumosStructure } from './APImodules';
import Backdrop from './Backdrop';
import { X, Search, Eye, ArrowRight } from 'lucide-react';
import LhumosSpaces from '../spaces.json';

const SearchResults = ({ query, onClose }) => {
  const [searchResults, setSearchResults] = useState({ results: [], count: 0, from: 0, totalSize: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [lhumosStructure, setLhumosStructure] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const searchData = await search(query, 'dataset');
        const structure = await getLhumosStructure();
        
        setSearchResults(searchData || { results: [], count: 0, from: 0, totalSize: 0 });
        setLhumosStructure(structure);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults({ results: [], count: 0, from: 0, totalSize: 0 });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [query]);

  const findDatasetInStructure = (datasetId, structure) => {
    if (!Array.isArray(structure)) {
      console.error("Invalid structure:", structure);
      return [];
    }
    const spaces = [];
    for (const space of structure) {
      const collectionInfo = findDatasetInCollections(datasetId, space.children);
      if (collectionInfo) {
        const spaceDetails = LhumosSpaces.find(s => s.id === space.id);
        spaces.push({ 
          ...collectionInfo, 
          spaceName: space.name, 
          spaceId: space.id,
          iconUrl: spaceDetails ? spaceDetails.iconurl : null
        });
      }
    }
    return spaces;
  };

  const findDatasetInCollections = (datasetId, collections, depth = 0) => {
    if (!Array.isArray(collections)) {
      return null;
    }
    for (let i = 0; i < collections.length; i++) {
      const collection = collections[i];
      if (Array.isArray(collection.children)) {
        const datasetIndex = collection.children.findIndex(child => child.id === datasetId);
        if (datasetIndex !== -1) {
          return { collectionId: collection.id, datasetIndex };
        }
        const nestedResult = findDatasetInCollections(datasetId, collection.children, depth + 1);
        if (nestedResult) {
          return nestedResult;
        }
      }
    }
    return null;
  };

  const getSpaceIndex = (spaceId) => {
    return LhumosSpaces.findIndex(space => space.id === spaceId);
  };

  const handleResultClick = (result, spaceInfo) => {
    if (!lhumosStructure) {
      console.error("Lhumos structure not available");
      return;
    }

    const spaceIndex = getSpaceIndex(spaceInfo.spaceId);
    if (spaceIndex === -1) {
      console.error("Space not found in LhumosSpaces array");
      return;
    }

    const link = `/player/${spaceIndex}/${spaceInfo.datasetIndex}/${spaceInfo.collectionId}/${result.id}`;
    
    onClose();
    navigate(link);
  };

  const { results, totalSize } = searchResults;

  const pathname = location.pathname;
  const isSpacePage = pathname.startsWith("/spaces/") || pathname.startsWith("/player/");
  const SPACE_INDEX = isSpacePage ? pathname.split("/")[2] : null;

  const spaceColor1 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color1 || "" : "#4883b8";
  const spaceColor2 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color2 || "" : "#5190c6";
  const spaceColor3 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.accent || "" : "#63a1ff";

  const containerStyle = SPACE_INDEX ? { backgroundColor: spaceColor1 } : {};

  const filteredResults = results.filter(result => 
    findDatasetInStructure(result.id, lhumosStructure).length > 0
  );

  return (
    <>
      <Backdrop onClick={onClose} />
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
        <div className="w-3/4 h-5/6 bg-opacity-90 backdrop-blur-md rounded-lg shadow-2xl overflow-hidden" style={containerStyle}>
          <div className="p-6 overflow-y-auto h-full relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl text-white font-bold flex items-center">
                <Search size={24} className="mr-2" />
                Search Results
              </h2>
              <button
                className="text-white focus:outline-none hover:opacity-80 transition-opacity"
                onClick={onClose}
              >
                <X size={24} />
              </button>
            </div>
            
            {isLoading ? (
              <p className="text-white">Loading...</p>
            ) : (
              <>
                <div className="mb-4 text-white text-sm flex justify-between items-center">
                  <span>
                    Showing <strong>{filteredResults.length}</strong> relevant results
                  </span>
                  <span className="opacity-80">
                    Total results: {totalSize}
                  </span>
                </div>
                <ul className="space-y-4">
                  {filteredResults.map((result) => {
                    const spaces = findDatasetInStructure(result.id, lhumosStructure);
                    return (
                      <li key={result.id} className="bg-white bg-opacity-90 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <div className="flex justify-between items-start">
                          <div className="flex-grow">
                            <h3 className="text-lg font-semibold mb-2" style={{ color: spaceColor3 }}>{result.name}</h3>
                            <p className="text-gray-700 text-sm line-clamp-2 mb-2">{result.description}</p>
                            <p className="text-xs text-gray-500">Dataset ID: {result.id}</p>
                          </div>
                          <div className="flex flex-col items-end gap-2 ml-4">
                            <p className="text-sm font-semibold mb-1">View in:</p>
                            {spaces.map((spaceInfo, index) => (
                              <button
                                key={index}
                                className="px-3 py-2 text-white rounded-full text-sm flex items-center transition-all duration-200 hover:shadow-md"
                                style={{ backgroundColor: spaceColor3 }}
                                onClick={() => handleResultClick(result, spaceInfo)}
                              >
                                {spaceInfo.iconUrl && (
                                  <img 
                                    src={spaceInfo.iconUrl} 
                                    alt={spaceInfo.spaceName} 
                                    className="w-5 h-5 mr-2 rounded-full"
                                  />
                                )}
                                {spaceInfo.spaceName}
                                <Eye size={16} className="ml-2" />
                                <ArrowRight size={16} className="ml-1" />
                              </button>
                            ))}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;