import React, { useState } from "react";
import { Link } from "react-router-dom";
import LhumosSpaces from "../spaces.json"; //spaces whitelist database

function DatasetLinkContainer({ spaceIndex, dataset, index, collectionId }) {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(true);
  const defaultThumbnail = "https://cdn-icons-png.flaticon.com/512/1027/1027650.png";

  const handleThumbnailError = () => {
    setThumbnailLoaded(false);
  };

  const thumbnailURL = `https://clowder.eessi.science/fileThumbnail/${dataset.thumbnail}/blob`;

  return (
    thumbnailLoaded ? (
<Link
    to={`/player/${spaceIndex}/${index}/${collectionId}/${dataset.id}`}
      className="block rounded-lg no-underline transition ease-in-out hover:translate-y-1"
    >
      <div
        className="bg-cover bg-center h-40 rounded-lg m-2 relative"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url("https://clowder.eessi.science/fileThumbnail/${dataset.thumbnail}/blob")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        
        <h6
          className="text-white rounded-full w-6 h-6 left-4 -top-2 flex justify-center items-center absolute"
          style={{
            backgroundColor: LhumosSpaces[spaceIndex].accent,
          }}
        >
          {index + 1}
        </h6>
        <h6 className="text-white px-2 text-sm pt-4">
          {dataset.name.substring(0, 80)}
          <img src={thumbnailURL} alt="Thumbnail" onError={handleThumbnailError} style={{ display: 'none' }} />
        </h6>
      </div>
    </Link>
    ) : (
<div
  className="block rounded-lg no-underline transition ease-in-out hover:translate-y-1"
  style={{
    background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url("${defaultThumbnail}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover', // Changed to 'cover' for better background image fit
  }}
>
        <div className="w-full p-4">
        <h5> video extraction in progress...</h5>
        </div>
      </div>
    )
  );
}

export default DatasetLinkContainer;
